<template>
  <div>
    <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>下发短信  </div>
    <el-card style="margin-top:10px">
      <el-select v-model="templateId" placeholder="请选择短信模板" style="margin-left:20px" @change="changelist()" clearable >
            <el-option
              v-for="item in SmsArr"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
      </el-select>
      <el-select v-model="taskId" placeholder="请选择任务名称" style="margin-left:20px"  clearable >
            <el-option
              v-for="item in taskArr"
              :key="item.id"
              :label="item.taskName"
              :value="item.id">
            </el-option>
      </el-select> 
      <div style="margin:20px">
         <el-button   type="text" size="mini"  @click="download1()" >下载《下发短信名单》模板</el-button>
         <el-upload
            class="upload-demo"
            :auto-upload="false"
            :on-change="changeUploadCoverImg"
            :show-file-list="true"
            accept=".xlsx, .xls"
            ref="upload"
            :limit="1"
            action>
            <el-button slot="trigger"  type="" size="mini" >选择文件</el-button>
            <el-button   class="Upload" type="primary" size="mini"  @click="Upload()" :disabled="flag">上传</el-button>
           </el-upload>
          
      </div>
      <div style="margin:20px;width:100%">
        <p>短信内容预览</p>
        <el-input
          style="width:80%"
          type="textarea"
          :rows="5"
          disabled
          v-model="textarea">
        </el-input>
        <br>
         <el-button  class="Send" type="primary"   @click="sendsys()">下发短信</el-button>
        
      </div>
   </el-card>
    <el-card style="margin-top:10px">
      <div slot="header" class="clearfix">
        <span>短信记录查询</span>
      </div>
      <div>
           <el-form :model="rule"   label-width="140px" class="demo-ruleForm">
              <el-form-item label="发送时间" class="item" >
                     <el-date-picker
                     style="width:400px" 
                        value-format="yyyy-MM-dd"
                        v-model="value1"
                        type="daterange"
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                 </el-form-item>
                <el-form-item label="姓名" class="item">
                     <el-input v-model="rule.name" style="width:300px" ></el-input>
                 </el-form-item>
                  <el-form-item label="手机号" class="item">
                     <el-input v-model="rule.mobile" style="width:300px" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                 </el-form-item>
                  <el-form-item label="模板名称" class="item">
                     <el-select v-model="rule.templateId" placeholder="请选择" style="width:260px" clearable>
                        <el-option
                            v-for="item in SmsArr"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                          </el-option>
                 </el-select>
                   </el-form-item> 
                 <el-form-item label="结果" class="item">
                     <el-select v-model="rule.status" placeholder="请选择" style="width:160px" clearable>
                        <el-option
                            v-for="item in Arr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                  <el-button type="primary" style="margin-left:140px;width:100px" @click="getDataList(1)">查询</el-button> 
        
        </el-form>
         <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="title"
        label="模板名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="姓名"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="mobile"
         label="手机号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="createdAt"
        label="发送时间">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          label="结果"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status==0">发送中</span>
            <span v-if="scope.row.status==1">成功</span>
            <span v-if="scope.row.status==2">失败</span>
          </template>
        </el-table-column>
    </el-table>
     <el-pagination
            background
            :before-remove="beforeRemove"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
      
     </div>     
    </el-card>  
  </div>
  
</template>
<script>
import { templateList,taskList,tempUpload,batchSend,logList,excel} from '@/api/individual'
export default {
  data() {
    return {
      flag:true,
      templateId:"",//短信模板id
      taskId:"",//任务id
      Arr: [{
          value: '0',
          label: '发送中'
        }, {
          value: '1',
          label: '发送成功'
        }, {
          value: '2',
          label: '发送失败'
        }],
      file:"",
      fileId:"",
      validationResults:'',
      SmsArr:[],
      taskArr:[],
       textarea:"",
        rule:{
            name:"",
            mobile:"",
            templateId:"",
            status:""
        },
        value1:"",
        load:false,
        tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
    };
  },
   mounted(){
     this.moun()
     this.getDataList();
  },
    methods: {
      moun(){
         templateList({}).then((data) => {
           if(data && data.code == 200) {
               this.SmsArr= data.data
            }
         })
          taskList({}).then((data) => {
           if(data && data.code == 200) {
               this.taskArr= data.data
            }
         })
      },
  changelist(){
     this.textarea=""
    this.SmsArr.map(item=>{
      if (this.templateId==item.id) {
        this.textarea=item.content
      }
    })
  },
    //上传
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUploadCoverImg(event) {
      this.file = event.raw;
      this.flag=false
     },
    Upload() {
      let params = new FormData();
        params.append("fileTypeCode", 100301 );
        params.append("auth", true);
        params.append("multipartFile", this.file);
        tempUpload({ params}).then((data) => {
         if(data.code == 200) {
            this.fileId = data.data.id
          } 
        })
      },
      sendsys(){
        let data1={
          templateId:this.templateId,
          taskId:this.taskId,
          fileId:this.fileId
        }
         batchSend(data1).then((data) => {
           if(data && data.code == 200) {
               this.$message({
                  message: '下发成功',
                  type: 'success'
                });
            }
         })
      },
      getDataList(a){
         if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
        this.load=true
        let data1={
            "current": this.currPage,
            "size": this.pageSize,
            "name":this.rule.name,
            "mobile": this.rule.batchNo,
            "templateId": this.rule.templateId,
            "status":this.rule.status,
            'startCreatedAt':this.value1==""||this.value1==null ? "" : (this.value1[0] + ' 00:00:00 '),
            'endCreatedAt':this.value1==""||this.value1==null? "" : (this.value1[1] + ' 23:59:59 '),
            "sidx": "created_at",
            "sortord": "DESC"
            
        }
         logList(data1).then((data) => {
             this.load = false
           if(data && data.code == 200) {
             this.totalCount = data.data.totalCount
              this.tableData= data.data.list
            }
         })
      },
      download1(){
        excel({}).then((data) => {
           if(data && data.code == 200) {
            window.open(data.data.url, '_self')
           }
          //  二进流下载
          // console.log(data,"uui");
          //  const blob = new Blob([data.data],{type:'application/vnd.ms-excel'})
          //   console.log(blob,"pp");
          //   if ('download' in document.createElement('a')) {
          //     const elink = document.createElement('a')
          //     elink.download = "下发短信名单"
          //     elink.style.display = 'none'
          //     elink.href = URL.createObjectURL(blob)
          //     document.body.appendChild(elink)
          //     elink.click()
          //     URL.revokeObjectURL(elink.href)
          //     document.body.removeChild(elink)
          //   } else {
          //     navigator.msSaveBlob(blob,"下发短信名单")
          //   }
         })
      },
       // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      }
  }
};
</script>
<style scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
    color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
  .Upload{
    margin-left: 10px;
  }
  .Send{
    margin-left: 45%;
    margin-top: 30px;
  }
  .clearfix span{
     font-size: 15px;
}
.item{
    display: inline-block;
}
/* 上传name一行显示 */
/* .upload-demo >>> .el-upload-list{ 
  display: inline-block; 
  transform: translateY(8px) 
  }
  .bott{
    transform: translateY(10px);
    margin-left:3%
  } */
</style>